@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/spacing';
@import '~arduino-sass/src/typography-sans';

.child-consent {
  display: flex;
  align-content: center;

  .preamble {
    font-size: 14px;
    margin-bottom: $space-large;
    line-height: 1.7;
    p {
      margin: 0 0 10px;
    }
  }

  .approvals {
    span {
      @extend .arduino-typography-xx-small;
      line-height: 1.4 !important;
    }
    .wrapper-status {
      min-height: initial !important;
    }
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    button {
      width: 100%;
    }
  }
}
