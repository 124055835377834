@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/spacing';

$arrow-size: 28px;

.header-container {
  padding: $space-xx-large $space-xx-large $space-x-large;
  display: flex;

  .icon-button {
    background-color: transparent;
    border: none;
    display: flex;
    color: $teal2;
    font-size: $arrow-size;
    cursor: pointer;
    padding: 0;
  }
}
