@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/spacing';
@import '~arduino-sass/src/typography-sans';

.optional-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  border-top: 1px solid $smoke;
  border-bottom: 1px solid $smoke;

  .reset {
    @extend .arduino-typography-xx-small-strong;
    color: $teal3 !important;
    cursor: pointer;
  }
}

.optional-password-modal {
  .optional-password-modal__heading {
    border-bottom: 1px solid $smoke;
  }

  .optional-password-modal__close {
    width: auto;
  }

  .content-wrapper {
    width: 380px;
    margin: 0 auto;
  }

  .description {
    @extend .arduino-typography-x-small;
    text-align: center;
    padding-bottom: $space-x-large;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
