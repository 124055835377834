.google-signin {
  display: flex;
  position: absolute;
  right: 2px;
  top: 0;
  z-index: 1;

  .google-icon {
    background-color: white;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ2cHgiIGhlaWdodD0iNDZweCIgdmlld0JveD0iMCAwIDQ2IDQ2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NiA0NjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiM0Mjg1RjQ7fS5zdDF7ZmlsbDojMzRBODUzO30uc3Qye2ZpbGw6I0ZCQkMwNTt9LnN0M3tmaWxsOiNFQTQzMzU7fS5zdDR7ZmlsbDpub25lO308L3N0eWxlPjx0aXRsZT5idG5fZ29vZ2xlX2xpZ2h0X25vcm1hbF9pb3M8L3RpdGxlPjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPjxnIGlkPSJfeDM5Xy1QQVRDSCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYwOC4wMDAwMDAsIC0xNjAuMDAwMDAwKSIgc2tldGNoOnR5cGU9Ik1TQXJ0Ym9hcmRHcm91cCI+PC9nPjxwYXRoIGlkPSJTaGFwZSIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCIgY2xhc3M9InN0MCIgZD0iTTMxLjYsMjMuMmMwLTAuNi0wLjEtMS4zLTAuMi0xLjhIMjN2My41aDQuOEMyNy42LDI2LDI3LDI2LjksMjYsMjcuNnYyLjNIMjlDMzAuNywyOC4zLDMxLjYsMjUuOSwzMS42LDIzLjJMMzEuNiwyMy4yeiIvPjxwYXRoIGlkPSJTaGFwZV8xXyIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCIgY2xhc3M9InN0MSIgZD0iTTIzLDMyYzIuNCwwLDQuNS0wLjgsNi0yLjJMMjYsMjcuNmMtMC44LDAuNS0xLjgsMC45LTMsMC45Yy0yLjMsMC00LjMtMS42LTUtMy43aC0zVjI3QzE2LjQsMzAsMTkuNSwzMiwyMywzMkwyMywzMnoiLz48cGF0aCBpZD0iU2hhcGVfMl8iIHNrZXRjaDp0eXBlPSJNU1NoYXBlR3JvdXAiIGNsYXNzPSJzdDIiIGQ9Ik0xOCwyNC43Yy0wLjItMC41LTAuMy0xLjEtMC4zLTEuN3MwLjEtMS4yLDAuMy0xLjdWMTloLTNjLTAuNiwxLjItMSwyLjYtMSw0czAuMywyLjgsMSw0TDE4LDI0LjdMMTgsMjQuN3oiLz48cGF0aCBpZD0iU2hhcGVfM18iIHNrZXRjaDp0eXBlPSJNU1NoYXBlR3JvdXAiIGNsYXNzPSJzdDMiIGQ9Ik0yMywxNy42YzEuMywwLDIuNSwwLjUsMy40LDEuM2wyLjYtMi42Yy0xLjYtMS41LTMuNi0yLjMtNi0yLjNjLTMuNSwwLTYuNiwyLTgsNWwzLDIuM0MxOC43LDE5LjIsMjAuNywxNy42LDIzLDE3LjZMMjMsMTcuNnoiLz48cGF0aCBpZD0iU2hhcGVfNF8iIHNrZXRjaDp0eXBlPSJNU1NoYXBlR3JvdXAiIGNsYXNzPSJzdDQiIGQ9Ik0xNCwxNGgxOHYxOEgxNFYxNHoiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    height: 50px;
    width: 50px;

    &:hover {
      & + .label {
        max-width: 100px;
        padding: 9px 15px;
      }
    }
  }

  .label {
    background-color: white;
    border-right: 2px solid #f4f4f4;
    display: inline-block;
    font-size: 12px;
    height: 50px;
    line-height: 1.4;
    max-width: 0;
    overflow: hidden;
    padding: 9px 0;
    position: absolute;
    right: 50px;
    top: 0;
    transition: max-width .2s, padding .2s;
    width: 100px;

    span {
      display: block;
      white-space: nowrap;
    }
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
