@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/spacing';
@import '~arduino-sass/src/typography-sans';

.alert-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $notifysuccess;
  background-color: #e8f5f3;
  height: 35px;
  padding: $space-x-small $space-xx-small $space-x-small $space-x-small;
  margin: $space-x-small 0;
  border-radius: 3px;

  transition: opacity 0.25s cubic-bezier(0, 0, 0.4, 1) 0.16s, transform 0.25s cubic-bezier(0, 0, 0.4, 1) 0.16s;
  transition-delay: 0ms;

  .message-wrapper {
    display: flex;
    align-items: center;

    svg {
      margin-right: $space-xx-small;
      path {
        fill: $notifysuccess;
      }
    }

    .message {
      @extend .arduino-typography-xx-small;
    }
  }
  .close-button {
    display: flex;
    cursor: pointer;
  }
}
