@import '~arduino-sass/src/spacing';
@import '~arduino-sass/src/variables';

$tooltip-width: $space-medium + $space-small;

.convert-coppa {
  input {
    font-size: 16px !important;
    margin: 0;
  }

  .next-btn {
    margin-bottom: 16px;
  }

  .form {
    width: 288px;
  }

  .form-input {
    display: flex;
    margin-bottom: $space-small;

    > :first-child {
      flex-grow: 2;
    }

    &.username-input {
      input {
        background: white;
        color: black;

        svg {
          width: 22px;
        }
      }
    }
  }

  .avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    background-color: $feather;
    border: 1px solid $fog;
    padding: 10px 10px 5px;

    button {
      margin-top: 5px !important;
    }
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: $tooltip-width;
  }

  .birthday {
    .zh-datepicker {
      background: white;

      * {
        box-sizing: content-box;
      }
    }

    .tooltip-row-wrapper {
      height: 58px;
    }
  }

  .tooltip-row-wrapper {
    margin-left: $space-small;
    height: 52px;
    width: $space-medium;
    display: flex;
    align-items: center;
  }

  .popover {
    width: 240px;
    z-index: 10;
  }
}
