@import '~arduino-sass/src/spacing';
@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/typography-sans';

$tooltip-width: $space-medium + $space-small;

.convert-regular,
.send-parent-email {
  input {
    font-size: 16px !important;
    margin: 0;
  }

  .next-btn {
    margin-bottom: 16px;
  }

  .form {
    max-width: 475px;
    margin: 16px auto;
    width: 100%;
  }

  .form-input {
    display: flex;
    margin-bottom: $space-small;
    position: relative;

    > :first-child {
      flex-grow: 2;
    }

    &.username-input {
      input {
        background: white;
        color: black;

        svg {
          width: 22px;
        }
      }
    }
  }

  .consents {
    margin-top: $space-x-large;
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .alert-box {
    padding: $space-small;
    border: 1px solid $notifyerror;
    background-color: #f9efed;
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin-bottom: $space-medium;

    svg {
      color: $notifyerror;
      margin-right: $space-x-small;
      width: 30px;
    }

    span {
      @extend .arduino-typography-xx-small;
      color: $notifyerror;
    }

    &.with-action {
      a {
        @extend .arduino-typography-xx-small-strong;
        color: $notifyerror;
        margin-left: $space-x-small;
        min-width: 65px;
      }
    }
  }

  .tooltip-row-wrapper {
    margin-left: $space-small;
    height: 52px;
    width: $space-medium;
    display: flex;
    align-items: center;
    position: absolute;
    right: -26px;
    top: 0;
  }

  .popover {
    width: 240px;
    z-index: 10;
  }
}
