@import '~arduino-sass/src/spacing';

.agegate {
  .form {
    width: 210px;
  }
  .birthday {
    position: relative;
    .zh-datepicker {
      background: white;

      * {
        box-sizing: content-box;
      }
    }

    .tooltip-row-wrapper {
      margin-left: $space-small;
      height: 52px;
      width: $space-medium;
      display: flex;
      align-items: center;
      position: absolute;
      right: -26px;
      top: 0;
    }
  }

  .popover {
    width: 240px;
    z-index: 10;
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
  }
}
