@import '~arduino-sass/src/arduino-sass';

$margin: 30px;
$lightgrey: #e5e3e3;

$header-height: 100px;
$footer-height: 230px;

$minHeight: 75vh;

* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
  outline: none;
}

// Render the font-face declarations
@include import-fonts;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  color: $dust;
  // font-family: $typonine-sans-regular;
  letter-spacing: 0.6px;
  margin: 0;
  display: flex;

  * {
    box-sizing: border-box;
  }
}

input.legacy {
  @extend %ardu-input;
}

a {
  color: $dust;
  text-decoration: none;
}

#root {
  flex: 1;
}

#app {
  min-height: $minHeight;
}

.error {
  background-color: $notifyerrorlight;
  color: $white;
  letter-spacing: 1.28px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.success {
  background-color: $notifysuccesslight;
  color: $white;
  letter-spacing: 1.28px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.privacy-settings {
  .success {
    margin: 25px 0 0;
  }
}

.select-wrapper {
  display: inline-block;
  max-width: 100%;
  position: relative;
  width: 100%;

  &::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $dust;
    content: '';
    height: 0;
    position: absolute;
    right: 12px;
    top: 15px;
    width: 0;
  }

  select {
    appearance: none;
    background-color: $white;
    border: 1px solid $lightgrey;
    border-radius: 0;
    color: $dust;
    // font-family: $typonine-sans-regular;
    font-size: 16px;
    line-height: normal;
    max-width: 100%;
    padding: 7px 14px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profile-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
  width: 100%;

  .row {
    margin: 35px 0;
    max-width: 950px;
    padding: 0 25px;
    width: 100%;

    .column {
      width: calc(50% - 20px);
    }

    @include breakpoint(1024) {
      .column {
        margin-left: auto;
        margin-right: auto;
        max-width: 500px;
        width: 100%;
      }
    }
  }

  @include breakpoint(480) {
    .row {
      margin: 25px 0;
      padding: 0 15px;
    }
  }
}

.progress-button {
  animation: move 2s linear infinite;
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-color: $teal2;
  background-size: 50px 50px;
  pointer-events: none;
}

// Standard syntax
@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

.account-settings {
  flex-direction: column;
  position: relative;

  section,
  .button-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    .busy {
      @extend %busy;
    }
  }

  section {
    .pass-container {
      border-left: 1px solid $fog;
      margin-left: -38px;
      padding: 0 0 15px 18px;

      &:last-child {
        padding-bottom: 0;
      }

      & + .error {
        margin-left: -20px;
        width: calc(100% + 20px);
      }

      .note {
        font-size: 13px;
        line-height: 16px;
        margin: 10px 0 5px;
      }
    }

    .change-link {
      align-items: center;
      display: flex;
    }
  }

  @include breakpoint(480) {
    section {
      flex-direction: column;
      position: relative;

      .pass-container {
        border: 0;
        margin: 0 0 15px;
        padding: 0;

        &:last-child {
          margin-bottom: 0;
        }

        & + .error {
          margin-left: 0;
          width: 100%;
        }
      }

      .link {
        position: absolute;
        right: 0;
        top: 8px;
      }
    }
  }
}

.password-settings {
  .value {
    margin: 10px 0 0;
  }
}

.delete-account {
  border-top: 1px solid $fog;
  padding-top: 23px;
}

.password-updated,
.delete-account,
.email-updated {
  font-size: 15px;
  margin-top: 25px;

  .success {
    line-height: 22px;
  }
}

.section-title {
  color: $jet;
  font-size: 20px;
  line-height: 20px;
  margin-top: 10px;
  text-transform: uppercase;

  a,
  .link {
    font-size: 16px;
    cursor: pointer;

    &.orders-history {
      float: right;
      position: relative;
    }
  }
}

.list-checkbox {
  @extend %ardu-checkbox;

  .custom-checkbox {
    margin-right: 15px;
  }

  .label-text {
    display: block;
    width: calc(100% - 40px);
  }
}

.box {
  @include breakpoint(480) {
    margin-bottom: 25px;
  }

  background-color: $clouds;
  border-top: 4px solid $teal2;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding: 12px 20px 27px;

  .section-content {
    margin-top: $margin;

    &.privacy-settings {
      .intro {
        border-bottom: 1px solid $fog;
        margin-bottom: 25px;
        padding-bottom: 23px;
      }
    }

    &.security {
      .two-step-action {
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $fog;
        border-top: 1px solid $fog;
        padding: 15px 0;

        label {
          flex-basis: 100%;
        }
      }

      .intro {
        margin-top: 25px;
      }
    }

    p {
      margin: 0 0 15px;
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0 10px;
      width: 100%;

      .secondary {
        margin-right: 10px;
      }
    }

    .row {
      flex-wrap: nowrap;
      margin: 0 0 15px;
      padding: 0;
    }

    label {
      align-items: center;
      display: flex;
      flex-basis: 20%;
      margin: 0 15px 0 0;
    }

    .list-checkbox {
      align-items: flex-start;
      margin: 0 0 25px;

      &:last-child {
        margin-bottom: 0;
      }

      @include breakpoint(480) {
        display: flex;
      }

      label {
        align-items: start;
      }

      .wrapper-status {
        display: none;
      }
    }

    .pre-wrapper {
      align-items: initial;
    }

    .value,
    .fields-wrapper {
      flex-basis: 0;
      flex-grow: 5;

      input {
        margin: 0;
        width: 100%;
      }
    }

    .fields-wrapper {
      .value {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @include breakpoint(480) {
      .row {
        flex-direction: column;
      }

      label,
      .value,
      .fields-wrapper {
        display: inline-block;
        width: 100%;
      }

      label {
        margin: 8px 0 4px;
      }
    }
  }

  &.account-settings {
    h4 {
      border-top: 1px solid $lightbronze;
      color: $gold;
      letter-spacing: 1.28px;
      margin: 35px 0 15px;
      padding-top: 9px;
      text-transform: uppercase;
    }

    .email-updated {
      margin-top: 15px;
    }
  }

  &.store-settings {
    position: relative;

    .change-settings {
      position: absolute;
      right: 0;
      top: 10px;
    }

    .btn-wrapper {
      display: inline-block;
      margin: 20px 0 25px;
      text-align: right;
      width: 100%;

      .secondary {
        margin-right: 10px;
      }
    }

    .orders-info {
      display: inline-block;
      position: relative;
      width: 100%;

      .orders-history {
        position: absolute;
        right: 0;
        top: 44px;
      }
    }
  }
}

.link {
  color: $teal2;
  cursor: pointer;
  text-decoration: none;
  text-transform: none;

  &:hover {
    color: $teal5;
  }

  &.forum {
    float: right;
  }

  h4 {
    border-top: 1px solid $lightbronze;
    color: $gold;
    // font-family: $typonine-sans-medium;
    font-weight: 500;
    margin: 35px 0 15px;
    padding-top: 6px;
    text-transform: uppercase;
  }
}

.arduino-modal {
  &.primary {
    @include modal($clouds, $teal2);
  }
}

// buttons
.btn {
  @include ardu-button($teal2, $teal5);
}

button {
  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.add-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .btn {
    display: inline-block;
    margin-top: $margin;
    width: calc(50% - 7px);
  }

  @include breakpoint(1024) {
    .btn {
      margin: $margin auto 0;
      width: 100%;
    }
  }
}

.pass-container {
  position: relative;

  .show-password {
    height: 20px;
    position: absolute;
    right: 11px;
    top: 7px;
    width: 20px;

    .show-pass,
    .hide-pass {
      background-color: transparent;
      background-repeat: no-repeat;
      border: 0;
      cursor: pointer;
      height: 20px;
      margin: 0;
      padding: 0;
      position: absolute;
      width: 20px;

      &:focus {
        outline: none;
      }
    }

    .show-pass {
      background-image: url('https://auth.arduino.cc/assets/img/pw-show.svg');
    }

    .hide-pass {
      background-image: url('https://auth.arduino.cc/assets/img/pw-hide.svg');
    }
  }
}

.temboo-edit {
  font-size: 17px;
  letter-spacing: 1.2px;

  a {
    display: block;
  }

  label {
    display: block;
    margin-bottom: 8px;
  }

  input {
    width: 100%;

    &:disabled {
      background-color: $fog;
    }
  }

  img {
    width: 50%;
  }

  .second-field {
    margin-top: 20px;
  }

  .error,
  .success {
    margin-bottom: 15px;
    text-align: left;
  }
}

.temboo-edit-title {
  margin: 35px 10px 35px 0;
}

.account-deleted {
  justify-content: center;
  margin: 120px auto;
  max-width: 700px;
  padding: 0 30px;
  text-align: center;

  h2 {
    // font-family: $typonine-sans-light;
    font-size: 36px;
    font-weight: 200;
    letter-spacing: 4px;
    line-height: 40px;
    margin: 20px 0;
    text-transform: uppercase;
  }

  @include breakpoint(768) {
    h2 {
      font-size: 30px;
      line-height: 34px;
    }
  }

  hr {
    width: 20%;
  }
}

// social icons
@font-face {
  // font-family: 'icomoon';
  // font-style: normal;
  // font-weight: normal;
  // src: url('fonts/icomoon.eot?n4q27m');
  // src:
  //   url('fonts/icomoon.eot?n4q27m#iefix') format('embedded-opentype'),
  //   url('fonts/icomoon.ttf?n4q27m') format('truetype'),
  //   url('fonts/icomoon.woff?n4q27m') format('woff'),
  //   url('fonts/icomoon.svg?n4q27m#icomoon') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  // Better Font Rendering ===========
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // use !important to prevent issues with browser extensions that change fonts
  font-family: 'icomoon' !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.load-profile {
  align-items: center;
  display: flex;
  height: calc(100vh - #{$header-height} - #{$footer-height});
}

@include breakpoint(768) {
  .load-profile {
    height: calc(100vh - 320px - 54px);
  }
}

@include breakpoint(540) {
  .load-profile {
    height: calc(100vh - 54px);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 650px) and (orientation: landscape) {
  .load-profile {
    height: calc(100vh - 54px);
  }
}

// spinner css
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $minHeight;
}

.spinner {
  border-radius: 50%;
  box-shadow: inset 0 0 0 1em;
  color: $teal2;
  font-size: 6px;
  height: 10em;
  margin: 20px auto;
  position: relative;
  // -webkit-transform: translateZ(0);
  // -ms-transform: translateZ(0);
  text-indent: -99999em;
  transform: translateZ(0);
  width: 10em;

  &::before,
  &::after {
    border-radius: 50%;
    content: '';
    position: absolute;
  }

  &::before {
    animation: load2 2s infinite ease 1.5s;
    background: $white;
    border-radius: 12em 0 0 12em;
    height: 12em;
    left: -1em;
    top: -1em;
    // -webkit-transform-origin: 6em 6em;
    transform-origin: 6em 6em;
    // -webkit-animation: load2 2s infinite ease 1.5s;
    width: 6em;
  }

  &::after {
    animation: load2 2s infinite ease;
    background: $white;
    border-radius: 0 12em 12em 0;
    height: 12em;
    left: 5em;
    top: -1em;
    // -webkit-transform-origin: 0px 6em;
    transform-origin: 0 6em;
    // -webkit-animation: load2 2s infinite ease;
    width: 6em;
  }
}

.confirm-section {
  margin-top: 60px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }

  img {
    width: 250px;
  }
}

@keyframes load2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// override footer-help z-index to stay below underlay and modals
#ino-footer-block .footer-help-container {
  z-index: 50;
}
