@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/spacing';
@import '~arduino-sass/src/typography-sans';

.child-confirm-link {
  display: flex;
  align-content: center;

  display: flex;
  align-content: center;

  .preamble {
    font-size: 14px;
    margin-bottom: $space-large;
    line-height: 1.7;
    p {
      margin: 0 0 10px;
    }
  }

  .approvals {
    span {
      @extend .arduino-typography-xx-small;
      line-height: 1.4 !important;
    }
    .wrapper-status {
      min-height: initial !important;
    }
  }

  .form-buttons {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
    }
    > * {
      margin: 0 0 $space-small;
    }
  }
}
