@import '~arduino-sass/src/spacing';
@import '~arduino-sass/src/variables';

$tooltip-width: $space-medium + $space-small;

.child-form {
  input {
    margin: 0;
    height: 40px !important;
    padding: 18px 20px 6px !important;
  }

  label {
    top: 8px !important;
  }

  .hasValue ~,
  input:focus ~ {
    label {
      transform: translateY(-7px) !important;
    }
  }

  .next-btn {
    margin-bottom: 16px;
  }

  .form-input {
    display: flex;
    margin-right: -28px;
    margin-bottom: 5px;

    > :first-child {
      flex-grow: 2;
    }

    &.username-input {
      input {
        background: white;
        color: black;

        svg {
          width: 22px;
        }
      }
    }
  }

  .avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    background-color: $feather;
    border: 1px solid $fog;
    padding: 10px 10px 5px;

    button {
      margin-top: 5px !important;
    }

    .avatar-picker {
      button {
        font-size: 0.75rem;
        letter-spacing: 0.01em;
        font-weight: 700;
        text-transform: none;
      }
    }
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    button {
      width: 100%;
    }
  }

  .birthday {
    .zh-datepicker {
      background: white;
      padding: 0 15px;

      input {
        padding: 0 5px !important;
        height: auto !important;
        margin: 15px 0 5px !important;
      }

      * {
        box-sizing: content-box;
      }
    }

    .tooltip-row-wrapper {
      height: 58px;
    }
  }

  .tooltip-row-wrapper {
    margin-left: $space-small;
    height: 52px;
    width: $space-medium;
    display: flex;
    align-items: center;
  }

  .popover {
    width: 240px;
    z-index: 10;
  }
}
