@import '~arduino-sass/src/variables';

.icon-wrapper {
  width: 16px;
  height: 16px;
  background-color: $fog;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  margin: 0 !important;

  svg {
    width: 4px;
  }
}
