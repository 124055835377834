@import '~arduino-sass/src/typography';
@import '~arduino-sass/src/variables';

$itemSeparator: 1px solid $fog;

.kidsList {
  margin: 20px 0;
  padding: 0;
  border-top: $itemSeparator;

  li {
    padding: 12px 0;
    display: flex;
    list-style: none;
    border-bottom: $itemSeparator;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 1rem;
    }

    .kidName {
      flex: 1;
    }

    .kidPending {
      @extend %arduino-typography-xx-small-strong;

      margin-right: 1rem;
      color: $notifyerror;
    }
  }
}

.addKidIcon {
  margin-right: 0.5rem;
  height: 1.3rem;
  width: 1.3rem;
}

%removeButtonStyle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.removeButtonStyle {
  @extend %removeButtonStyle;

  outline: inherit;
}

.kidActions {
  margin: 0;
  padding: 0;
  margin-left: -5px;
  margin-right: -5px;

  &--element {
    @extend %removeButtonStyle;

    font-size: 14px;
    margin: 4px 0;
    padding-bottom: 4px;
    border-top: $itemSeparator;
    padding-top: 4px;
    display: block;
    width: 100%;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;

    &:first-child {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.kidsmodal {
  padding: 0 2rem;

  &--error {
    @extend %arduino-typography-xx-small-strong;

    color: $notifyerror;
    text-align: center;
  }

  &--actions {
    display: flex;
    text-align: right;
    justify-content: center;

    button {
      margin-left: 1rem;
    }
  }
}

.child-created-tooltip {
  background-color: $white;
  border-radius: 3px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  width: 256px;

  p {
    @extend %arduino-typography-xx-small;
    margin: 0 0 24px;
  }

  button {
    align-self: flex-end;
  }

  & .tooltip-arrow {
    visibility: hidden;

    &,
    &::before {
      position: absolute;
      width: 20px;
      height: 20px;
      background: inherit;
    }

    &::before {
      visibility: visible;
      content: '';
      transform: rotate(45deg);
      z-index: 2;
    }
  }

  &[data-placement^='top'] .tooltip-arrow {
    bottom: -10px;
  }
  &[data-placement^='bottom'] .tooltip-arrow {
    top: -10px;
  }
  &[data-placement^='left'] .tooltip-arrow {
    top: 16px;
    right: -10px;
  }
  &[data-placement^='right'] .tooltip-arrow {
    top: 16px;
    left: -10px;
  }
}

.minor-login-box {
  background-color: $white;
  padding: 16px;
  margin-top: 20px;
  font-size: 12px;

  p {
    margin: 0 0 5px !important;
  }

  &__link {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    line-height: 1;
    position: relative;

    svg {
      cursor: pointer;
      color: $teal3;
      width: 24px;
      height: 24px;
      margin-left: 10px;

      &:hover {
        color: $teal2;
      }
    }
  }

  &__success {
    color: $notifysuccess;
    font-weight: bold;
    position: absolute;
    bottom: 6px;
    font-size: 12px;
    right: -5px;
  }
}
