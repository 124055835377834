@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/spacing';
@import '~arduino-sass/src/breakpoints';
@import '~arduino-sass/src/typography-sans';

.child-layout {
  display: flex;
  min-height: calc(100vh - 42px);

  @include breakpoint(960, 'max') {
    min-height: calc(100vh - 103px);
  }

  .main-container {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;

    &.coppa {
      background-color: $white;
    }

    .main {
      display: flex;
      padding: 0 $space-xxx-large;
      color: $black;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      @include breakpoint(960, 'max') {
        padding: 0 $space-x-large;
      }

      > section {
        margin: 0 auto 40px;
        max-width: 450px;
        border: 1px solid $fog;
        padding: 40px;
        display: block;
        border-radius: 6px;

        .large {
          margin: 0 0 $space-x-large;
        }

        .description {
          font-size: 14px;
          color: $jet;
          margin: 0 0 25px;
        }

        h1 {
          margin: 0 0 $space-x-small 0;
        }

        form {
          max-width: 315px;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: $space-xx-large;

          .form-row {
            margin-bottom: $space-x-large;
            position: relative;

            .wrapper {
              background-color: $white;
            }

            .tooltip-row-wrapper {
              position: absolute;
              right: -26px;
              top: 16px;
            }
          }

          button {
            align-self: flex-end;
            margin-top: $space-x-large;
          }
        }
      }
    }
  }

  .logo-wrapper {
    svg {
      width: 70px;
      fill: $teal3;
    }
  }
}

.children-form-h1 {
  font-size: 16px;
  color: $jet;
  span {
    color: #f26727;
  }
}

.avatar-picker__content {
  justify-content: flex-start !important;
  padding-bottom: 20px;
  span {
    margin: 5px;
  }
  .avatar-picker__avatar-hover {
    font-size: 12px !important;
  }
}
