@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/spacing';
@import '~arduino-sass/src/breakpoints';
@import '~arduino-sass/src/typography-sans';

.error-layout {
  display: flex;
  min-height: calc(100vh - 42px);

  @include breakpoint(960, 'max') {
    min-height: 100vh;
  }

  .main-container {
    background-color: $clouds;
    display: flex;
    flex-direction: column;
    flex: 1 0 67%;

    .main {
      width: 800px - $space-xx-large * 2;
      display: flex;
      justify-content: center;
      padding: $space-medium $space-xx-large $space-xx-large $space-xx-large;
      margin: 36px auto;

      color: $black;
      background-color: white;
      border: 1px solid $silver;
      border-radius: 6px;

      @include breakpoint(960, 'max') {
        padding: 0 $space-x-large;
        min-height: 100vh;
        margin: 0;
        width: 100%;
      }

      section {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
    }
  }

  img {
    max-height: 200px;
    margin-top: $space-x-large;
  }

  h1 {
    @extend .arduino-typography-xx-large;
    font-size: 36px;
  }

  a {
    color: $teal3;
    font-weight: bold;
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: $space-x-large;
  }
}
