@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/breakpoints';
@import '~arduino-sass/src/inputfield';

$lightgrey: #e5e3e3;

.profile-header {
  align-items: center;
  background-color: $clouds;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  width: 100%;

  &.public-profile {
    background-color: transparent;
    margin-top: 20px;
  }
}

.title {
  @include breakpoint(1024) {
    .username-container {
      margin: 25px auto 0;
      max-width: 330px;
      text-align: center;
    }
  }

  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 950px;
  padding: 0 25px;
  width: 100%;

  .normalize-weight {
    font-weight: normal;
  }

  .img-wrapper {
    border-radius: 50%;
    height: 140px;
    margin: auto;
    overflow: hidden;
    width: 140px;
  }

  .img-container {
    margin: 0;
    padding: 0;
    text-align: center;
    width: calc(50% - 20px);

    img {
      background-color: $white;
      cursor: pointer;
      display: inline-block;
      height: auto;
      min-height: 140px;
      min-width: 140px;
      width: 45%;

      &.custom-avatar {
        padding: 0;
      }
    }

    &.public {
      img {
        cursor: default;
      }
    }
  }

  .upload-link {
    margin-top: 12px;
    text-transform: uppercase;
    font-size: 16px;
  }

  .upload-avatar-form {
    flex: 1;
    font-size: 15px;
    line-height: 19px;
    margin-right: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    width: calc(50% - 40px);

    .profile-pic-instructions {
      margin: 0 0 20px;

      h5 {
        color: $gold;
        font-size: 16px;
        letter-spacing: 1.28px;
        margin: 0 0 15px;
        text-transform: uppercase;
      }

      p {
        margin: 0 0 7px;
        text-align: left;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .upload-pic {
      background-color: $white;
      display: inline-block;
      margin: 0 0 20px;
      max-width: 100%;
      min-height: 240px;
      position: relative;
      width: 240px;
      align-self: center;

      .preview-upload {
        max-width: 100%;

        img {
          margin: 20px;
          width: calc(100% - 40px);
        }
      }

      label {
        border: 0;
        cursor: pointer;
        display: inline-block;
        height: 100%;
        min-height: 240px;
        width: 100%;

        span {
          font-size: 16px;
          left: 50%;
          letter-spacing: 1.28px;
          position: absolute;
          text-align: center;
          text-transform: uppercase;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          white-space: nowrap;
        }
      }

      input {
        display: none;
      }
    }

    .section-title {
      margin-bottom: 20px;
    }
  }

  .profileinfo {
    margin: 0;
    padding: 0;
    width: calc(50% - 20px);
  }

  @include breakpoint(1024) {
    .upload-avatar-form {
      border-bottom: 1px solid $smoke;
      margin: 0 auto 20px;
      max-width: 330px;
      padding: 0 0 54px;
      text-align: center;
      width: 100%;
    }

    .profileinfo,
    .img-container {
      width: 100%;
    }

    .img-container {
      img {
        height: auto;
        width: 20%;
      }
    }
  }

  h1 {
    margin-bottom: 0;
  }

  h2 {
    font-weight: normal;
    margin-top: 0;
  }
}

.upload-avatar-buttons {
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;

  .secondary {
    margin-right: 10px;
  }
}

.username-container {
  margin: 0;
  padding: 0;

  h1 {
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0.6px;
    margin: 0 0 15px;

    span {
      display: none;
    }
  }

  @include breakpoint(1024) {
    h1 {
      font-size: 30px;
      line-height: 34px;
    }
  }

  .description-bio {
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 20px;
    margin: 0 0 10px;
    text-align: left;
  }

  textarea {
    width: 100%;
  }
}

.social {
  display: inline-block;
  margin-top: 10px;
  width: 100%;

  .bio {
    margin: 0 0 20px;

    textarea {
      border: 1px solid $lightgrey;
      color: $dust;
      // font-family: $typonine-sans-regular;
      font-size: 16px;
      letter-spacing: 0.6px;
      line-height: 20px;
      max-width: 100%;
      min-height: 100px;
      min-width: 100%;
      padding: 15px 18px;
      width: 100%;

      &::placeholder {
        color: $dust;
        letter-spacing: 1.28px;
        text-transform: uppercase;
      }

      &:focus {
        border: 1px solid $smoke;
        outline: none;

        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  .web,
  .facebook,
  .twitter,
  .github {
    position: relative;

    input {
      margin-left: 35px;
      width: calc(100% - 35px);
      border: 1px solid $fog;

      &::placeholder {
        text-transform: uppercase;
      }

      &:focus {
        border: 1px solid $smoke;
      }
    }

    a {
      display: inline-block;
      margin-left: 35px;
      padding: 7px 0;
      text-align: left;
      transition: color 0.2s;
      width: calc(100% - 35px);

      &:hover,
      &:active,
      &:focus {
        color: $teal2;
      }
    }

    svg {
      @include size(16px);

      left: 3px;
      position: absolute;
      top: 10px;
    }
  }
}

@include breakpoint(1024) {
  .title {
    flex-direction: column;
  }

  .upload-avatar-buttons {
    display: flex;

    .btn {
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  .profile-header {
    padding: 35px 0 45px;
  }
}
